import React from "react";
import { Layout } from "components";
import { ContactFormBlock, Loader } from "components/ACF";
import WordPressPreview from "components/WordPressPreview/WordPressPreview";
import { graphql } from "gatsby";

interface Page {
  data: Queries.PageByIdQuery;
}

const Page = ({ data }: Page): JSX.Element => {
  const { post, wp } = data;
  const { content, title, acfLayout, seo } = post;
  const {
    webAppSettings: {
      webAppSettings: { globalContactBlock },
    },
  } = wp;

  return (
    <Layout pageTitle={title} seo={seo}>
      <div className="page">
        {acfLayout?.layout?.length > 0 ? (
          <Loader
            data={acfLayout.layout.map(({ ...layout }) => ({
              ...layout,
            }))}
            location={seo?.breadcrumbs}
          />
        ) : (
          content && (
            <div className="layout">
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
          )
        )}
        {globalContactBlock && globalContactBlock.row && <ContactFormBlock data={globalContactBlock} />}
      </div>
    </Layout>
  );
};

export default WordPressPreview({ postType: "pages" })(Page);

export const PageQueryById = graphql`
  query PageById($id: String!) {
    # selecting the current page by id
    post: wpPage(id: { eq: $id }) {
      id
      content
      title
      ...SEOPageQuery
      isFrontPage
      wordpressId: databaseId
      acfLayout {
        fieldGroupName
        layout {
          ...HeroQuery
          ...ContactFormBlockQuery
          ...TestimonialFeedQuery
          ...FeaturedPostsQuery
          ...TeamProfilesQuery
          ...FlexibleBlockQuery
        }
      }
    }
    wp {
      webAppSettings {
        webAppSettings {
          globalContactBlock {
            row {
              contentType
              formId
              content
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 80, placeholder: BLURRED, width: 700)
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
